@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Roboto');
$icon-font-path: "../node_modules/bootstrap-sass/assets/fonts/bootstrap/";
@import "../node_modules/bootstrap/scss/bootstrap";

// $fa-font-path : "../node_modules/font-awesome/fonts";
// @import "../node_modules/font-awesome/scss/font-awesome";
@import "../node_modules/font-awesome/css/font-awesome.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/ngx-toastr/toastr.css";
@import "../node_modules/socicon/css/socicon.css";
@import "../node_modules/metrojs/release/MetroJs.Full/MetroJs.css";
@import "../node_modules/dragula/dist/dragula.min.css";
@import "../node_modules/pace/themes/pace-theme-flash.css";
@import "../node_modules/awesome-bootstrap-checkbox/awesome-bootstrap-checkbox.css";
@import "../node_modules/dropzone/dist/min/dropzone.min.css";
@import "../node_modules/@swimlane/ngx-datatable/themes/bootstrap.css";
@import "../node_modules/@swimlane/ngx-datatable/assets/icons.css";
@import "../node_modules/fullcalendar/dist/fullcalendar.min.css";
@import "../node_modules/leaflet/dist/leaflet.css";

@import "app/theme/styles/variables";
@import "app/theme/styles/mixins";
@import "app/theme/styles/bootstrap-override";
@import "app/theme/styles/libs-override";
@import "app/theme/styles/base";

@import "app/theme/styles/custom_table_1.scss";
@import "app/theme/styles/custom_table_2.scss";
@import "app/theme/styles/custom_table_3.scss";
@import "app/theme/styles/custom_table_4.scss";
@import "app/theme/styles/custom_table_5.scss";

@-webkit-keyframes spin-cubeGridScaleDelay {
  0%, 70%, 100% {
    -webkit-transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
  }
}

@-moz-keyframes spin-cubeGridScaleDelay {
  0%, 70%, 100% {
    -moz-transform: scale3D(1, 1, 1);
  }
  35% {
    -moz-transform: scale3D(0, 0, 1);
  }
}

@keyframes spin-cubeGridScaleDelay {
  0%, 70%, 100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}

.spin-outer {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index: -1;
}
.spin-container {
    height:100%;
    width:100%;
    display:table;
}
.spin-inner {
    vertical-align:middle;
    height:100%;
    display:table-cell;
}
.spin-cube-grid {
    width: 100px;
    height: 100px;
    margin: 0 auto;
    .spin-cube {
        width: 33%;
        height: 33%;
        background-color: #fff;
        float: left;
        -webkit-animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
        -moz-animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
        animation: spin-cubeGridScaleDelay 1.3s infinite ease-in-out;
    }
    .spin-cube1 {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    .spin-cube2 {
        -webkit-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .spin-cube3 {
        -webkit-animation-delay: 0.4s;
        -moz-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    .spin-cube4 {
        -webkit-animation-delay: 0.1s;
        -moz-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }
    .spin-cube5 {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    .spin-cube6 {
        -webkit-animation-delay: 0.3s;
        -moz-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    .spin-cube7 {
        -webkit-animation-delay: 0s;
        -moz-animation-delay: 0s;
        animation-delay: 0s;
    }
    .spin-cube8 {
        -webkit-animation-delay: 0.1s;
        -moz-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }
    .spin-cube9 {
        -webkit-animation-delay: 0.2s;
        -moz-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
}
::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
}
::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #cccccc;
    }
    &:active{
        background: #888888;
    }
}
::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 0px;
    &:hover{
        background: #666666;
    }
    &:active{
        background: #333333;
    }
}
::-webkit-scrollbar-corner {
     background: transparent;
}


ng-scrollbar {
    --scrollbar-size: 4px !important;
    --scrollbar-thumb-color: rgba(204,204,204,0.4) !important;
    --scrollbar-thumb-hover-color: #ccc !important;
    --scrollbar-border-radius: 4px !important;
    --scrollbar-hover-size: 8px !important;
    --scrollbar-padding: 2px !important;
}
