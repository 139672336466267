/**** Colors (recommended use HEX colors) ****/
$main-color: #004446;
$default-color:#fff;
$dark-color: rgb(37, 14, 0);
$default-text-color:#061f00;

$body-bg-gradient-color-1: #004d00;
$body-bg-gradient-color-2:  #ccffcc;

$sidebar-bg-color: #006164;
$sidebar-item-active-bg-color: #00979B;


$primary-color: #013d5f;
$success-color: #2d922d;
$info-color: #248dad;
$warning-color: #f79a17;
$danger-color: #bf1725;
